<template>
  <div>
    <h3>旭德教育各区域联系方式</h3>
    <div class="df-wrap f-sb">
      <el-card
        class="items"
        shadow="hover"
        v-for="(item, index) in formdata"
        :key="index"
      >
        <p>{{ item.areaName || "--" }}</p>
        <div class="item1">
          <span class="item2"> 咨询热线： </span>
          <span>{{ item.phoneNum || "--" }}</span>
        </div>
        <div>
          <span class="item2">详细地址： </span>
          <span>{{ item.address || "--" }}</span>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { getAreaSitePage } from "@/api";
export default {
  name: "contactus",
  data() {
    return {
      formdata: [],
    };
  },
  mounted() {
    this.getAreaSitePageApi();
  },
  methods: {
    getAreaSitePageApi() {
      getAreaSitePage({
        current: 1,
        size: 500,
      }).then((res) => {
        if (res.code == 1) {
          let resData = res.data || {};
          this.formdata = resData.records || [];
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
h3 {
  text-align: center;
  margin: 20px 0;
}
.items {
  width: 48%;
  margin-bottom: 10px;
  border-radius: 5px;
  p {
    color: #1ab394;
  }
  .item1 {
    margin: 10px 0;
    .item2 {
      color: #999999;
    }
  }
}
</style>